import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import React, { useState, useEffect } from 'react';
import Filters from '../../componentes/Filters/index.js';
import { useNavigate } from "react-router-dom";

function useWindowDimensions() {
    const hasWindow = typeof window !== 'undefined';
    function getWindowDimensions() {
        const width = hasWindow ? window.innerWidth : null;
        const height = hasWindow ? window.innerHeight : null;
        return {
            width,
            height,
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        if (hasWindow) {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [hasWindow]);

    return windowDimensions;
}

const HomePage = () => {
    let { height, width } = useWindowDimensions();
    height = height * 0.65;

    const navigate = useNavigate();
    const [selectedFilters, setSelectedFilters] = useState({
        equipment_identifiers: [],
        ship_owners: [],
        describe_info: [],
        severities: [],
        start_date: dayjs().subtract(7, 'day'),
        end_date: dayjs(),
    });
    const baseUrl = process.env.REACT_APP_LISTEN_ADDRESS
    const user = JSON.parse(localStorage.getItem("user"));
    const [eventQuantity, setEventQuantity] = useState(['']);
    const [eventsBySeverity, setEventsBySeverity] = useState([])
    const [eventsByAlert, SetEventsByAlert] = React.useState([0])
    const [eventsByVessel, setEventsByVessel] = React.useState([0])
    const [alertsBarlabels, setAlertsBarlabels] = React.useState([0])
    const [vesselsBarlabels, setVesselsBarlabels] = React.useState([0])

    useEffect(() => {
        const fetchData = async () => {

            try {
                const filterParams = new URLSearchParams();
                Object.entries(selectedFilters).forEach(([key, value]) => {
                    if (Array.isArray(value)) {
                        value.forEach(val => filterParams.append(key, val));
                    } else if (value) {
                        filterParams.append(key, key.includes('date') ? dayjs(value).format() : value);
                    }
                });

                const response = await fetch(`${baseUrl}/sync-event/dashboard?${filterParams}`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem("user");
                        navigate("/login", { replace: true });
                    } else {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                }

                let data = await response.json();

                setEventQuantity(data.countTotalEvents);

                const uniqueDescribeInfo = Object.keys(data.countEventsWithDescribeInfo);

                SetEventsByAlert(uniqueDescribeInfo.map(info => data.countEventsWithDescribeInfo[info]))
                setAlertsBarlabels(uniqueDescribeInfo)

                const uniqueEquipment = Object.keys(data.countEventsPerEquipment);

                setEventsByVessel(uniqueEquipment.map(info => data.countEventsPerEquipment[info]));
                setVesselsBarlabels(uniqueEquipment.map(String));

                setEventsBySeverity([
                    { label: 'Normal', value: data.countSeverity['Normal'], color: 'green' },
                    { label: 'Crítico', value: data.countSeverity['Crítico'], color: 'darkred' },
                ]);


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (user.token) {
            fetchData();
        }
    }, [baseUrl, user.token, selectedFilters]);

    const handleFilterChange = (filterType, selectedOptions) => {
        setSelectedFilters(prevFilters => ({ ...prevFilters, [filterType]: selectedOptions }));
    };

    const alertsData = eventsByAlert
    const vesselsData = eventsByVessel
    const alertsXLabels = alertsBarlabels
    const vesselsXLabels = vesselsBarlabels
    const piedata = eventsBySeverity;


    return (
        < >

            <Paper variant="outlined" sx={{ m: 2, p: 4, d: 'flex', flexDirection: 'row', flexWrap: 'wrap', boxSizing: 'border-box', }}>
                <Filters onFilterChange={handleFilterChange} />
                <Box sx={{ display: 'flex', gap: 4, mt: 2, flexWrap: 'wrap', justifyContent: 'center', }}>

                    <Box sx={{ minWidth: 345, display: 'flex', flexDirection: 'column', mx: 1, gap: 4, flex: 1, boxSizing: 'border-box', }}>
                        <Card sx={{ flex: '1', p: 2, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', }}>

                            <Typography gutterBottom fontWeight="bold" fontSize="1.8rem" component="div">
                                Quantidade de alertas
                            </Typography>

                            <Typography gutterBottom variant="h2" component="div">
                                {eventQuantity}
                            </Typography>

                        </Card>

                        <Card sx={{ p: 2, position: 'relative', flex: '2' }}>
                            <Typography align="center" gutterBottom fontWeight="bold" fontSize="1.8rem" component="div">
                                Relação de Alertas Críticos
                            </Typography>
                            <PieChart
                                slotProps={{
                                    legend: {
                                        direction: 'row',
                                        position: { vertical: 'bottom', horizontal: 'middle' },
                                        padding: 0,
                                    },
                                }}
                                series={[
                                    {
                                        arcLabel: (item) => `${item.value}`,
                                        arcLabelMinAngle: 25,
                                        arcLabelRadius: '60%',
                                        startAngle: -90,
                                        endAngle: 90,
                                        data: piedata,
                                    },
                                ]}
                                height={height * 0.4}
                                margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
                            />
                        </Card>
                    </Box>

                    <Card sx={{ p: 1, flex: 2, display: 'flex', flexDirection: 'column', minWidth: 300, minHeight: 300, boxSizing: 'border-box' }}>
                        <Typography align="center" gutterBottom fontWeight="bold" fontSize="1.8rem" component="div">
                            Desvios por alerta
                        </Typography>

                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            {alertsData.length > 0 ? (
                                <BarChart
                                    height={height}
                                    series={[{ data: alertsData }]}
                                    barLabel={(item, context) => {

                                        return context.bar.height < 60 ? null : item.value?.toString();
                                    }}
                                    sx={{ p: 2, m: 0, flex: 1 }}
                                    xAxis={[{
                                        data: alertsXLabels,
                                        scaleType: 'band',
                                        tickLabelStyle: { angle: 25, textAnchor: 'start', fontSize: 12 },
                                    }]}

                                />
                            ) : (
                                <Typography variant="body2" color="textSecondary" align="center" sx={{ flex: 1 }}>
                                    Sem dados para exibir.
                                </Typography>
                            )}
                        </div>
                    </Card>

                    <Card sx={{ p: 1, flex: 2, display: 'flex', flexDirection: 'column', minWidth: 300, minHeight: 300, boxSizing: 'border-box' }}>
                        <Typography align="center" gutterBottom fontWeight="bold" fontSize="1.8rem" component="div">
                            Alertas por embarcação
                        </Typography>
                        {vesselsData.length > 0 && (
                            <BarChart
                                height={height}
                                series={[{ data: vesselsData, id: 'vessels' }]}
                                barLabel={(item, context) => {

                                    return context.bar.height < 60 ? null : item.value?.toString();
                                }}
                                sx={{ p: 2, m: 0, }}
                                xAxis={[{
                                    scaleType: 'band',
                                    data: vesselsXLabels,
                                    tickLabelStyle: { angle: 15, textAnchor: 'start', fontSize: 12 },
                                }]}
                            />
                        )}
                        {vesselsData.length === 0 && (
                            <Typography variant="body2" color="textSecondary" align="center">
                                Sem dados para exibir.
                            </Typography>
                        )}
                    </Card>
                </Box>

            </Paper>
        </>
    );
}

export default HomePage;
