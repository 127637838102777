import LoginPage from "../pages/Login/LoginPage";

export const auth_routes = [
    { 
        path: "/", 
        ele: <LoginPage></LoginPage>, 
    },
    { 
        path: "/login", 
        ele: <LoginPage></LoginPage>, 
    }
]