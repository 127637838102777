import React, { useState, useEffect, useRef } from 'react';
import {
    Paper,
    Typography,
    Box,
    IconButton,
    Modal,
    Divider,
    MenuItem,
    Select,
    InputLabel,
    OutlinedInput,
    FormControl,
    Checkbox,
    ListItemText,
    Button,
    Avatar,
    Grid
} from '@mui/material';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import unavaibleImage from '../../assets/images/unavaibleImage.png';
import InfoIcon from '@mui/icons-material/Info'
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';


function formatDate(datetime) {
    // Create a new Date object from the input datetime
    const date = new Date(datetime);

    // Adjust for GMT-3
    const gmtOffset = -3; // GMT-3 offset in hours
    const adjustedDate = new Date(date.getTime() + (gmtOffset) * 60 * 60 * 1000);

    const day = String(adjustedDate.getDate()).padStart(2, '0');
    const month = String(adjustedDate.getMonth() + 1).padStart(2, '0');
    const year = adjustedDate.getFullYear();
    const hours = String(adjustedDate.getHours()).padStart(2, '0');
    const minutes = String(adjustedDate.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
}

const load_image = (path) => {
    if (path) {
        // Construa o caminho completo para a imagem na pasta public
        const resolvedPath = `${process.env.PUBLIC_URL}${path}`;
        console.log(resolvedPath);
        return resolvedPath;
    } else {
        return (unavaibleImage);
    }
};

const EventModal = ({ open, selectedRow, onClose }) => {
    const canvasRef = useRef(null);
    const canvasHoverRef = useRef(null);    
    
    const [selectedDeviations, setSelectedDeviations] = useState([]);

    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    const baseUrl = process.env.REACT_APP_LISTEN_ADDRESS;
    const [downloadStatus, setDownloadStatus] = useState('idle');
    const [imageObtainedDate, setImageObtainedDate] = useState(null);
    const [imageObtainedPath, setImageObtainedPath] = useState(null);

    const handleModalClose = () => {
        // Reset the states when closing
        setImageObtainedDate(null);
        setImageObtainedPath(null);
        setSelectedDeviations([]);
        setDownloadStatus('idle'); // Reset download status if necessary
        onClose(); // Call the passed in onClose function
    };
    


    useEffect(() => {


        const fetchSyncEventMediaImage = async () => {
            
            try {
                const response = await fetch(
                    `${baseUrl}/sync-event-media-image/${selectedRow.id}`,
                    { headers: { Authorization: `Bearer ${user.token}` } }
                );

                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('user');
                        navigate('/login', { replace: true });
                    }

                    else if (response.status === 404) {
                        setDownloadStatus('idle');
                        return;
                    }

                    else {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                }

                const responseData = await response.json();
                const { need_download, retry_download_count, modified_at, path } = responseData;

                
                if (path) {
                    setDownloadStatus('success');
                    setImageObtainedDate(modified_at);
                    setImageObtainedPath(path);
                } else if (retry_download_count >= 10 && path == null) {
                    setDownloadStatus('failed');
                } else if (retry_download_count < 10 && need_download === true) {
                    setDownloadStatus('requested');
                }
            } catch (error) {
                console.error("Failed to fetch sync event media image", error);
            } 
        };
        

        if (user?.token && selectedRow) {
            fetchSyncEventMediaImage();
        }
    }, [user?.token, baseUrl, navigate, selectedRow]);


    const handleDownloadRequest = async () => {
        try {
            if (downloadStatus === 'idle') {
                await fetch(`${baseUrl}/sync-event-media-image`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user.token}`
                    },
                    body: JSON.stringify({ event_ref: selectedRow.id })
                });
                setDownloadStatus('requested');
            } else if (downloadStatus === 'failed') {
                await fetch(`${baseUrl}/sync-event-media-image`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user.token}`
                    },
                    body: JSON.stringify({ event_ref: selectedRow.id, need_download: true, retry_download_count: 0 })
                });
                setDownloadStatus('requested');
            }
        } catch (error) {
            console.error("Failed to request download", error);
        }
    };



    const handleHover = (event) => {
        const image = document.getElementById('modal-image');
        const canvas = canvasHoverRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = 1280;
        canvas.height = 720;
        const wRatio = canvas.width / image.naturalWidth;
        const hRatio = canvas.height / image.naturalHeight;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = "red"; // Use color from palette
        ctx.lineWidth = "3.5";
        ctx.beginPath();
        ctx.rect(event.bbox.pos_x * wRatio, event.bbox.pos_y * hRatio, event.bbox.pos_width * wRatio, event.bbox.pos_height * hRatio);
        ctx.stroke();
        ctx.closePath();
    };

    const clearCanvas = () => {
        const canvas = canvasHoverRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    };


    const selectColor = (number) => {
        const palette = [
            "#e01616", // Red
            "#f6f40b", // Yellow
            "#2affbb", // Cyan
            "#123cdf", // Blue
            "#e51983", // Magenta
            "#ff6bff", // Light Magenta
            "#00ff00", // Green
            "#ff00ff", // Light Cyan
            "#00ffff", // Bright Cyan
            "#ff9900", // Orange
            "#ffcc00", // Yellow Orange
            "#ccff00", // Lime Green
            "#00ff66", // Bright Green
            "#0099ff", // Bright Blue
            "#cc00ff",  // Light Purple
            "#ff3399", // Pink
            "#33ff99", // Mint Green
            "#6600ff", // Purple
            "#ff4d4d", // Coral Red
            "#ff66cc", // Light Pink
            "#99ff66", // Lime
            "#3399ff", // Sky Blue
            "#ff9933", // Amber
            "#00ccff", // Electric Blue
            "#660066", // Dark Purple
            "#99ccff", // Periwinkle Blue
            "#ff80bf", // Salmon Pink
            "#33ffcc", // Turquoise
            "#ff4da6", // Hot Pink
            "#ffff80"  // Light Yellow
        ];
        return palette[number % palette.length];
    };



    const handleDeviationChange = (event) => {
        const selectedEvents = event.target.value;
        setSelectedDeviations(selectedEvents);
        const image = document.getElementById('modal-image');
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = 1280;
        canvas.height = 720;
        const wRatio = canvas.width / image.naturalWidth;
        const hRatio = canvas.height / image.naturalHeight;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        selectedEvents.forEach((event, index) => {
            const color = selectColor(event.id);
            ctx.strokeStyle = color; // Use color from palette
            ctx.lineWidth = "3.5";
            ctx.beginPath();
            ctx.rect(event.pos_x * wRatio, event.pos_y * hRatio, event.pos_width * wRatio, event.pos_height * hRatio);
            ctx.stroke();
            ctx.closePath();
        });
    };


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '60%',
                maxWidth: '90%',
                maxHeight: '90%',
                overflowY: 'auto',
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: 4,
                p: 4
            }}>
                <IconButton
                    aria-label="Close"
                    onClick={handleModalClose}
                    sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        color: 'text.secondary',
                        zIndex: 1
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h5" component="h2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <NotificationsIcon style={{ marginRight: 8 }} /> Detalhes do Evento
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        {selectedRow && (
                            <Paper variant="outlined" sx={{ p: 1, position: 'relative', borderRadius: '8px', overflow: 'hidden' }}>
                                <div style={{ position: 'relative', overflow: 'hidden', borderRadius: '8px' }}>
                                    <img
                                        id="modal-image"
                                        src={load_image(imageObtainedPath)}
                                        alt="Event frame"
                                        style={{ width: '100%', verticalAlign: 'middle', display: 'block', borderRadius: '8px' }}
                                    />
                                    <canvas
                                        ref={canvasRef}
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none', borderRadius: '8px' }}
                                    />

                                    <canvas
                                        ref={canvasHoverRef}
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none', borderRadius: '8px' }}
                                    />
                                </div>
                            </Paper>
                        )}
                    </Grid>

                    <Grid item xs={4}>
                        {selectedRow && (
                            <>

                                <Typography id="modal-description" gutterBottom>
                                    <strong>Embarcação:</strong> {selectedRow.ship_name}
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                                <Typography id="modal-description" gutterBottom>
                                    <strong>Armador:</strong> {selectedRow.ship_owner}
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                                <Typography id="modal-description" gutterBottom>
                                    <strong>Data e Hora:</strong> {formatDate(selectedRow.datetime)}
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                                <Typography id="modal-description" gutterBottom>
                                    <strong>Severidade:</strong> {selectedRow.severity}
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                                <Typography variant="body2" gutterBottom>
                                    <em>Selecione os eventos abaixo:</em>
                                </Typography>
                                <FormControl sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="label-events">Eventos</InputLabel>
                                    <Select
                                        labelId={`label-events`}
                                        id={`multiple-checkbox-events`}
                                        multiple
                                        value={selectedDeviations}
                                        onChange={handleDeviationChange}
                                        input={<OutlinedInput label={"Eventos"} />}
                                        renderValue={(selected) => {
                                            if (typeof selectedRow.events === 'string') {
                                                return selectedRow.events.label;
                                            } else {
                                                return selected.map(value => {
                                                    const selectedEvent = selectedRow.events.find(event => event.bbox === value);
                                                    return selectedEvent ? selectedEvent.label : '';
                                                }).join(', ');
                                            }
                                        }}
                                        MenuProps={MenuProps}
                                        sx={{ width: '100%' }}
                                    >
                                        {typeof selectedRow.events === 'string' ? (
                                            <MenuItem key={0} value={selectedRow.events}
                                                onMouseEnter={() => handleHover(selectedRow.events)}
                                                onMouseLeave={() => clearCanvas()}>
                                                <Checkbox checked={selectedDeviations.indexOf(selectedRow.events) > -1} />
                                                <ListItemText primary={selectedRow.events.label} />
                                            </MenuItem>
                                        ) : (
                                            Array.isArray(selectedRow.events) && selectedRow.events.map((event, index) => (
                                                <MenuItem key={index} value={event.bbox}
                                                    onMouseEnter={() => handleHover(event)}
                                                    onMouseLeave={() => clearCanvas()}>
                                                    <Checkbox checked={selectedDeviations.indexOf(event.bbox) > -1} />
                                                    <ListItemText primary={event.label} />
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </FormControl>

                                <Paper sx={{
                                    p: 2,
                                    width: '100%',
                                    backgroundColor: downloadStatus === 'requested' ? 'rgba(255, 255, 0, 0.1)' :
                                        downloadStatus === 'failed' ? 'rgba(255, 0, 0, 0.1)' :
                                            downloadStatus === 'success' ? 'rgba(0, 255, 0, 0.1)' :
                                                'rgba(0, 0, 255, 0.1)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                    {downloadStatus === 'requested' && (
                                        <>
                                            <Avatar sx={{ bgcolor: 'primary.main', mb: 1 }}>
                                                <HourglassEmptyIcon />
                                            </Avatar>
                                            <Typography variant="body2" gutterBottom>
                                                Imagem solicitada, aguardando retorno da embarcação
                                            </Typography>
                                        </>
                                    )}
                                    {downloadStatus === 'failed' && (
                                        <>
                                            <Avatar sx={{ bgcolor: 'error.main', mb: 1 }}>
                                                <ErrorOutlineIcon />
                                            </Avatar>
                                            <Typography variant="body2" gutterBottom>
                                                Erro ao fazer download da imagem
                                            </Typography>
                                            <Button variant="contained" color="primary" onClick={handleDownloadRequest}>
                                                Tentar novamente
                                            </Button>
                                        </>
                                    )}
                                    {downloadStatus === 'success' && (
                                        <>
                                            <Avatar sx={{ bgcolor: 'success.main', mb: 1 }}>
                                                <CheckCircleIcon />
                                            </Avatar>
                                            <Typography variant="body2" gutterBottom>
                                                Imagem obtida em: {formatDate(imageObtainedDate)}
                                            </Typography>
                                        </>
                                    )}
                                    {(downloadStatus !== 'requested' && downloadStatus !== 'failed' && downloadStatus !== 'success') && (
                                        <>
                                            <Avatar sx={{ bgcolor: 'info.main', mb: 1 }}>
                                                <InfoIcon />
                                            </Avatar>
                                            <Typography variant="body2" gutterBottom>
                                                Imagem indisponível
                                            </Typography>
                                            <Button variant="contained" color="primary" onClick={handleDownloadRequest}>
                                                Solicitar Download
                                            </Button>
                                        </>
                                    )}
                                </Paper>

                            </>
                        )}
                    </Grid>
                </Grid>

            </Box>

        </Modal>
    );
};

export default EventModal;
