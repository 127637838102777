import React, { useState, useEffect, useRef } from 'react';
import {
    Paper,
    Typography,
    Box,
    IconButton,
    Modal,
    Divider,
    MenuItem,
    Select,
    InputLabel,
    OutlinedInput,
    FormControl,
    Checkbox,
    ListItemText,
    Button,
    Avatar,
    Grid
} from '@mui/material';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import unavaibleImage from '../../assets/images/unavaibleImage.png'



import InfoIcon from '@mui/icons-material/Info'
import CloseIcon from '@mui/icons-material/Close';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import NotificationsIcon from '@mui/icons-material/Notifications';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { useTheme, styled, darken, lighten } from '@mui/material/styles';
import ScrollbarWrapper from '../../componentes/themes/scrollbar';
import { useNavigate } from 'react-router-dom';
import Filters from '../../componentes/Filters';
import dayjs from 'dayjs';

function formatDate(datetime) {
    const date = new Date(datetime);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
}

const load_image = (path) => {
    if (path) {
        // Construa o caminho completo para a imagem na pasta public
        const resolvedPath = `${process.env.PUBLIC_URL}${path}`;
        console.log(resolvedPath);
        return resolvedPath;
    } else {
        return (unavaibleImage);
    }
};

const columns = [
    {
        // field: "thumbnail",
        // headerName: "Imagem",
        // width: 200,
        // renderCell: (params) => {
        //     return (
        //         <Box
        //             sx={{
        //                 display: 'flex',
        //                 justifyContent: 'center',
        //                 alignItems: 'center',
        //                 height: '100%'
        //             }}
        //         >
        //             <img src={"https://c4.wallpaperflare.com/wallpaper/694/597/166/platform-offshore-metso-building-wallpaper-preview.jpg"} alt="Thumbnail" style={{ width: '100%', height: 85 }} />
        //         </Box>
        //     );
        // }
    },
    { field: 'ship_owner', headerName: 'Armador', type: 'string', flex: 1, sortable: false },
    { field: 'ship_name', headerName: 'Embarcação', type: 'string', flex: 1, sortable: false },
    { field: 'events', headerName: 'Desvios', type: 'string', flex: 1, sortable: false, valueGetter: (params) => renderEvents(params.row.events) },
    {
        field: 'datetime',
        headerName: 'Data e Hora',
        type: 'string',
        valueGetter: ({ value }) => formatDate(value),
        flex: 1,
        sortable: false,
    },
    {
        field: 'severity',
        headerName: 'Severidade',
        type: 'singleSelect',
        valueOptions: ['Baixo', 'Normal', 'Médio', 'Crítico'],
        flex: 1,
        sortable: false,
    },
];

const renderEvents = (events) => {
    return events.map(event => event.label).join(', ');
};

const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .super-app-theme--Open': {
        backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--Filled': {
        backgroundColor: getBackgroundColor(
            theme.palette.success.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--PartiallyFilled': {
        backgroundColor: getBackgroundColor(
            theme.palette.warning.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--Crítico': {
        backgroundColor: getBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
            },
        },
    },
}));

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));

function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36-.144-.573-.221-1.172-.221-1.789V75.1h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.972 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <Box sx={{ mt: 1 }}>Nenhum evento encontrado</Box>
        </StyledGridOverlay>
    );
}

const alerts = [
    { label: 'Pessoa caída', value: 'falling' },
    { label: 'Sem macacão', value: 'no-coverall' },
    { label: 'Sem colete', value: 'no-jacket' },
    { label: 'Sem capacete', value: 'no-helmet' },
    { label: 'Sem botas', value: 'no-boots' },
    { label: 'Sem luvas', value: 'no-gloves' },
    { label: 'Área proibida', value: 'intrusion' },
    //{ label: 'Sem protetor auricular', value: 'no-ear-protector' },
    //{ label: 'Sem óculos', value: 'no-glasses' },
];

const alertMapping = alerts.reduce((map, alert) => {
    map[alert.value] = alert.label;
    return map;
}, {});

const HomePage = () => {
    const canvasRef = useRef(null);
    const canvasHoverRef = useRef(null);
    const [paginationModel, setPaginationModel] = useState({ pageSize: 5, page: 0 });
    const [rowCount, setRowCount] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedDeviations, setSelectedDeviations] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({
        equipment_identifiers: [],
        ship_owners: [],
        describe_info: [],
        severities: [],
        start_date: dayjs().subtract(7, 'day'),
        end_date: dayjs(),
    });
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    const baseUrl = process.env.REACT_APP_LISTEN_ADDRESS;
    const [downloadStatus, setDownloadStatus] = useState('idle');
    const [imageObtainedDate, setImageObtainedDate] = useState(null);
    const [imageObtainedPath, setImageObtainedPath] = useState(null);


    useEffect(() => {


        const fetchData = async () => {
            try {
                const filterParams = new URLSearchParams();
                Object.entries(selectedFilters).forEach(([key, value]) => {
                    if (Array.isArray(value)) {
                        value.forEach(val => filterParams.append(key, val));
                    } else if (value) {
                        filterParams.append(key, key.includes('date') ? dayjs(value).format() : value);
                    }
                });

                const response = await fetch(
                    `${baseUrl}/sync-event?page=${paginationModel.page}&page_size=${paginationModel.pageSize}&${filterParams}`,
                    { headers: { Authorization: `Bearer ${user.token}` } }
                );

                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('user');
                        navigate('/login', { replace: true });
                    } else {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                }

                const responseData = await response.json();
                setRowCount(responseData.total_count);
                setRows(
                    responseData.data.map(event => ({
                        id: event.id,
                        ship_name: event.equipment_display_name || event.equipment_identifier,
                        ship_owner: event.equipment_ship_owner || 'Desconhecido',
                        events: event.event_objects.map(obj => ({
                            label: alertMapping[obj.describe_info] || obj.describe_info,
                            bbox: obj
                        })),
                        datetime: event.occur_time,
                        severity: event.severity,
                    }))
                );
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const fetchSyncEventMediaImage = async () => {
            try {
                const response = await fetch(
                    `${baseUrl}/sync-event-media-image/${selectedRow.id}`,
                    { headers: { Authorization: `Bearer ${user.token}` } }
                );

                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('user');
                        navigate('/login', { replace: true });
                    }

                    else if (response.status === 404) {
                        setDownloadStatus('idle');
                        return;
                    }

                    else {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                }

                const responseData = await response.json();
                const { need_download, retry_download_count, modified_at, path } = responseData;

                console.log("need download: ", need_download)
                
                if (path) {
                    setDownloadStatus('success');
                    setImageObtainedDate(modified_at);
                    setImageObtainedPath(path);
                } else if (retry_download_count >= 10 && path == null) {
                    setDownloadStatus('failed');
                } else if (retry_download_count < 10 && need_download == true) {
                    setDownloadStatus('requested');
                }
            } catch (error) {
                console.error("Failed to fetch sync event media image", error);
            }
        };

        if (selectedRow?.id) {
            fetchSyncEventMediaImage();
        } else if (user?.token) {
            fetchData();
        }


    }, [user?.token, baseUrl, paginationModel, selectedFilters, navigate, selectedRow?.id]);


    const handleFilterChange = (filterType, selectedOptions) => {
        setSelectedFilters(prevFilters => ({ ...prevFilters, [filterType]: selectedOptions }));
    };

    const handleRowClick = (params) => {
        setSelectedRow(params.row);
        setOpenModal(true);
        setSelectedDeviations([]);
    };

    const handleDownloadRequest = async () => {
        try {
            if (downloadStatus === 'idle') {
                await fetch(`${baseUrl}/sync-event-media-image`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user.token}`
                    },
                    body: JSON.stringify({ event_ref: selectedRow.id })
                });
                setDownloadStatus('requested');
            } else if (downloadStatus === 'failed') {
                await fetch(`${baseUrl}/sync-event-media-image`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user.token}`
                    },
                    body: JSON.stringify({ event_ref: selectedRow.id, need_download: true, retry_download_count: 0 })
                });
                setDownloadStatus('requested');
            }
        } catch (error) {
            console.error("Failed to request download", error);
        }
    };



    const handleHover = (event) => {
        const image = document.getElementById('modal-image');
        const canvas = canvasHoverRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = 1280;
        canvas.height = 720;
        const wRatio = canvas.width / image.naturalWidth;
        const hRatio = canvas.height / image.naturalHeight;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = "red"; // Use color from palette
        ctx.lineWidth = "3.5";
        ctx.beginPath();
        ctx.rect(event.bbox.pos_x * wRatio, event.bbox.pos_y * hRatio, event.bbox.pos_width * wRatio, event.bbox.pos_height * hRatio);
        ctx.stroke();
        ctx.closePath();
    };

    const clearCanvas = () => {
        const canvas = canvasHoverRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    };


    const selectColor = (number) => {
        const palette = [
            "#e01616", // Red
            "#f6f40b", // Yellow
            "#2affbb", // Cyan
            "#123cdf", // Blue
            "#e51983", // Magenta
            "#ff6bff", // Light Magenta
            "#00ff00", // Green
            "#ff00ff", // Light Cyan
            "#00ffff", // Bright Cyan
            "#ff9900", // Orange
            "#ffcc00", // Yellow Orange
            "#ccff00", // Lime Green
            "#00ff66", // Bright Green
            "#0099ff", // Bright Blue
            "#cc00ff",  // Light Purple
            "#ff3399", // Pink
            "#33ff99", // Mint Green
            "#6600ff", // Purple
            "#ff4d4d", // Coral Red
            "#ff66cc", // Light Pink
            "#99ff66", // Lime
            "#3399ff", // Sky Blue
            "#ff9933", // Amber
            "#00ccff", // Electric Blue
            "#660066", // Dark Purple
            "#99ccff", // Periwinkle Blue
            "#ff80bf", // Salmon Pink
            "#33ffcc", // Turquoise
            "#ff4da6", // Hot Pink
            "#ffff80"  // Light Yellow
        ];
        return palette[number % palette.length];
    };



    const handleDeviationChange = (event) => {
        const selectedEvents = event.target.value;
        setSelectedDeviations(selectedEvents);
        const image = document.getElementById('modal-image');
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = 1280;
        canvas.height = 720;
        const wRatio = canvas.width / image.naturalWidth;
        const hRatio = canvas.height / image.naturalHeight;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        selectedEvents.forEach((event, index) => {
            const color = selectColor(event.id);
            ctx.strokeStyle = color; // Use color from palette
            ctx.lineWidth = "3.5";
            ctx.beginPath();
            ctx.rect(event.pos_x * wRatio, event.pos_y * hRatio, event.pos_width * wRatio, event.pos_height * hRatio);
            ctx.stroke();
            ctx.closePath();
        });
    };


    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        closeModal();
    }

    const closeModal = () => {
        setOpenModal(false);
        setSelectedRow(false);
        setImageObtainedDate(null);
        setImageObtainedPath(null);
        setSelectedDeviations([]);
    }

    const theme = useTheme();
    const fontSize = window.innerWidth < theme.breakpoints.values.sm ? '10px' : window.innerWidth < theme.breakpoints.values.md ? '12px' : '14px';
    const dataGridStyle = { fontSize, minWidth: 600 };
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <Paper sx={{ m: 2, p: 4, minHeight: '500px' }}>
            <Typography gutterBottom fontWeight="bold" fontSize="1.8rem" component="div">
                Registro de eventos
            </Typography>
            <Filters onFilterChange={handleFilterChange} />
            <Box sx={{ display: 'flex', gap: 3, mt: 1 }}>
                <ScrollbarWrapper>
                    <StyledDataGrid
                        rowCount={rowCount}
                        style={dataGridStyle}
                        rows={rows}
                        columns={columns}
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        paginationMode="server"
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[5, 10, 25, 50, 100]}
                        disableColumnMenu
                        autoHeight
                        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                        getRowHeight={() => 100}
                        getRowClassName={(params) => `super-app-theme--${params.row.severity}`}
                        onRowClick={handleRowClick}
                    />
                </ScrollbarWrapper>
            </Box>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '60%',
                    maxWidth: '90%',
                    maxHeight: '90%',
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: 4,
                    p: 4
                }}>
                    <IconButton
                        aria-label="Close"
                        onClick={() => closeModal()}
                        sx={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            color: 'text.secondary',
                            zIndex: 1
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h5" component="h2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                        <NotificationsIcon style={{ marginRight: 8 }} /> Detalhes do Evento
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            {selectedRow && (
                                <Paper variant="outlined" sx={{ p: 1, position: 'relative', borderRadius: '8px', overflow: 'hidden' }}>
                                    <div style={{ position: 'relative', overflow: 'hidden', borderRadius: '8px' }}>
                                        <img
                                            id="modal-image"
                                            src={load_image(imageObtainedPath)}
                                            style={{ width: '100%', verticalAlign: 'middle', display: 'block', borderRadius: '8px' }}
                                        />
                                        <canvas
                                            ref={canvasRef}
                                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none', borderRadius: '8px' }}
                                        />

                                        <canvas
                                            ref={canvasHoverRef}
                                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none', borderRadius: '8px' }}
                                        />
                                    </div>
                                </Paper>
                            )}
                        </Grid>

                        <Grid item xs={4}>
                            {selectedRow && (
                                <>

                                    <Typography id="modal-description" gutterBottom>
                                        <strong>Embarcação:</strong> {selectedRow.ship_name}
                                    </Typography>
                                    <Divider sx={{ my: 1 }} />
                                    <Typography id="modal-description" gutterBottom>
                                        <strong>Armador:</strong> {selectedRow.ship_owner}
                                    </Typography>
                                    <Divider sx={{ my: 1 }} />
                                    <Typography id="modal-description" gutterBottom>
                                        <strong>Data e Hora:</strong> {formatDate(selectedRow.datetime)}
                                    </Typography>
                                    <Divider sx={{ my: 1 }} />
                                    <Typography id="modal-description" gutterBottom>
                                        <strong>Severidade:</strong> {selectedRow.severity}
                                    </Typography>
                                    <Divider sx={{ my: 1 }} />
                                    <Typography variant="body2" gutterBottom>
                                        <em>Selecione os eventos abaixo:</em>
                                    </Typography>
                                    <FormControl sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="label-events">Eventos</InputLabel>
                                        <Select
                                            labelId={`label-events`}
                                            id={`multiple-checkbox-events`}
                                            multiple
                                            value={selectedDeviations}
                                            onChange={handleDeviationChange}
                                            input={<OutlinedInput label={"Eventos"} />}
                                            renderValue={(selected) => {
                                                if (typeof selectedRow.events === 'string') {
                                                    return selectedRow.events.label;
                                                } else {
                                                    return selected.map(value => {
                                                        const selectedEvent = selectedRow.events.find(event => event.bbox === value);
                                                        return selectedEvent ? selectedEvent.label : '';
                                                    }).join(', ');
                                                }
                                            }}
                                            MenuProps={MenuProps}
                                            sx={{ width: '100%' }}
                                        >
                                            {typeof selectedRow.events === 'string' ? (
                                                <MenuItem key={0} value={selectedRow.events}
                                                    onMouseEnter={() => handleHover(selectedRow.events)}
                                                    onMouseLeave={() => clearCanvas()}>
                                                    <Checkbox checked={selectedDeviations.indexOf(selectedRow.events) > -1} />
                                                    <ListItemText primary={selectedRow.events.label} />
                                                </MenuItem>
                                            ) : (
                                                Array.isArray(selectedRow.events) && selectedRow.events.map((event, index) => (
                                                    <MenuItem key={index} value={event.bbox}
                                                        onMouseEnter={() => handleHover(event)}
                                                        onMouseLeave={() => clearCanvas()}>
                                                        <Checkbox checked={selectedDeviations.indexOf(event.bbox) > -1} />
                                                        <ListItemText primary={event.label} />
                                                    </MenuItem>
                                                ))
                                            )}
                                        </Select>
                                    </FormControl>

                                    <Paper sx={{
                                        p: 2,
                                        width: '100%',
                                        backgroundColor: downloadStatus === 'requested' ? 'rgba(255, 255, 0, 0.1)' :
                                            downloadStatus === 'failed' ? 'rgba(255, 0, 0, 0.1)' :
                                                downloadStatus === 'success' ? 'rgba(0, 255, 0, 0.1)' :
                                                    'rgba(0, 0, 255, 0.1)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}>
                                        {downloadStatus === 'requested' && (
                                            <>
                                                <Avatar sx={{ bgcolor: 'primary.main', mb: 1 }}>
                                                    <HourglassEmptyIcon />
                                                </Avatar>
                                                <Typography variant="body2" gutterBottom>
                                                    Imagem solicitada, aguardando retorno da embarcação
                                                </Typography>
                                            </>
                                        )}
                                        {downloadStatus === 'failed' && (
                                            <>
                                                <Avatar sx={{ bgcolor: 'error.main', mb: 1 }}>
                                                    <ErrorOutlineIcon />
                                                </Avatar>
                                                <Typography variant="body2" gutterBottom>
                                                    Erro ao fazer download da imagem
                                                </Typography>
                                                <Button variant="contained" color="primary" onClick={handleDownloadRequest}>
                                                    Tentar novamente
                                                </Button>
                                            </>
                                        )}
                                        {downloadStatus === 'success' && (
                                            <>
                                                <Avatar sx={{ bgcolor: 'success.main', mb: 1 }}>
                                                    <CheckCircleIcon />
                                                </Avatar>
                                                <Typography variant="body2" gutterBottom>
                                                    Imagem obtida em: {formatDate(imageObtainedDate)}
                                                </Typography>
                                            </>
                                        )}
                                        {(downloadStatus !== 'requested' && downloadStatus !== 'failed' && downloadStatus !== 'success') && (
                                            <>
                                                <Avatar sx={{ bgcolor: 'info.main', mb: 1 }}>
                                                    <InfoIcon />
                                                </Avatar>
                                                <Typography variant="body2" gutterBottom>
                                                    Imagem indisponível
                                                </Typography>
                                                <Button variant="contained" color="primary" onClick={handleDownloadRequest}>
                                                    Solicitar Download
                                                </Button>
                                            </>
                                        )}
                                    </Paper>

                                </>
                            )}
                        </Grid>
                    </Grid>

                </Box>

            </Modal>

        </Paper>
    );
};

export default HomePage;
