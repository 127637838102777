// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.acordion{
    width: 100%; max-height: 200px ; overflow-y: scroll ; gap: 5px  ;
}

.acordion::-webkit-scrollbar {
    width: 0.5rem;
  }
   
  .acordion::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #41a3af55;
    border-radius: 1px;
  }
   
  .acordion::-webkit-scrollbar-thumb {
    background-color: #21838f;
    outline: 0px;
    border-radius: 1px;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Status/KeepAlive.css"],"names":[],"mappings":";;AAEA;IACI,WAAW,EAAE,kBAAkB,EAAE,mBAAmB,EAAE,UAAU;AACpE;;AAEA;IACI,aAAa;EACf;;EAEA;IACE,mCAAmC;IACnC,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;EACpB","sourcesContent":["\n\n.acordion{\n    width: 100%; max-height: 200px ; overflow-y: scroll ; gap: 5px  ;\n}\n\n.acordion::-webkit-scrollbar {\n    width: 0.5rem;\n  }\n   \n  .acordion::-webkit-scrollbar-track {\n    box-shadow: inset 0 0 6px #41a3af55;\n    border-radius: 1px;\n  }\n   \n  .acordion::-webkit-scrollbar-thumb {\n    background-color: #21838f;\n    outline: 0px;\n    border-radius: 1px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
