import React, { useState, useEffect } from 'react';
import { Paper, Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OnlineAvatar from './OnlineAvatar';
import UnavailableAvatar from './UnavailableAvatar';
import OfflineAvatar from './OfflineAvatar';
import './KeepAlive.css'
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, ptBR } from '@mui/x-date-pickers';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import 'dayjs/locale/pt-br';
import { PieChart } from '@mui/x-charts/PieChart';
import { useNavigate } from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';

dayjs.locale('pt-br');



const StatusPage = () => {
    const navigate = useNavigate();
    const requestAbortController = React.useRef(null);
    const initialValue = dayjs(new Date());
    const [expanded, setExpanded] = useState(null);
    const [equipments, setEquipments] = useState([]);
    const user = JSON.parse(localStorage.getItem("user"));
    const baseUrl = process.env.REACT_APP_LISTEN_ADDRESS;
    const [equipmentsByID, setEquipmentsByID] = useState([]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);

    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 0,
        p: 4,
    };

    const [buttonkey, setButtonkey] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loadingDates, setLoadingDates] = useState(false);

    const getThisKey = async (event) => {
        const selectedKey = event.target.getAttribute('data-key');
        const keyIsSelected = await setButtonkey(selectedKey);
        // Trigger the fetchDates function
        fetchDates(selectedKey);
        handleOpen();
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseUrl}/equipment`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem("user");
                        navigate("/login", { replace: true });
                    } else {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                }
                const data = await response.json();
                setEquipments(data);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (user.token) {
            fetchData();
        }
    }, [baseUrl, user.token]);

    const renderAvatar = (lastKeepalive) => {
        const currentDate = new Date();
        const lastKeepaliveDate = lastKeepalive ? new Date(lastKeepalive) : null;
        const differenceInDays = lastKeepaliveDate && Math.floor((currentDate - lastKeepaliveDate) / (24 * 60 * 60 * 1000));

        if (differenceInDays !== null && differenceInDays <= 5) {
            return <OnlineAvatar />;
        } else if (lastKeepaliveDate !== null) {
            return <OfflineAvatar />;
        } else {
            return <UnavailableAvatar />;
        }
    };

    const calculateEquipmentCounts = () => {
        let onlineCount = 0;
        let offlineCount = 0;
        let unavailableCount = 0;

        equipments.forEach((equipment) => {
            const lastKeepalive = equipment.last_keepalive_ts_received;
            const currentDate = new Date();
            const lastKeepaliveDate = lastKeepalive ? new Date(lastKeepalive) : null;
            const differenceInDays = lastKeepaliveDate && Math.floor((currentDate - lastKeepaliveDate) / (24 * 60 * 60 * 1000));

            if (differenceInDays !== null && differenceInDays <= 5) {
                onlineCount++;
            } else if (lastKeepaliveDate !== null) {
                offlineCount++;
            } else {
                unavailableCount++;
            }
        });
        return [onlineCount, offlineCount, unavailableCount];
    };

    const [onlineCount, offlineCount, unavailableCount] = calculateEquipmentCounts();
    const data = [
        { label: 'Online', value: onlineCount, color: 'green' },
        { label: 'Offline', value: offlineCount, color: 'darkred' },
        { label: 'Indisponivel', value: unavailableCount, color: 'grey' },
    ]

    const formatDate = (dateStr) => {
        if (!dateStr) return '';
        const dateObj = new Date(dateStr);
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear();
        return `${day}-${month}-${year}`;
    };

    function ServerDay(props) {
        const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
        const isSelected =
            !outsideCurrentMonth && highlightedDays.includes(day.format('YYYY-MM-DD'));

        return (
            <Badge
                key={props.day.toString()}
                overlap="circular"
                badgeContent={isSelected ? '🟢' : ''}
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Badge>
        );
    }

    function FetchDates(date, { signal }) {
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                const currentDate = dayjs(date); // Convertendo a data para o formato dayjs
                const currentMonth = currentDate.format('YYYY-MM'); // Obtendo o mês atual no formato YYYY-MM
                let daysToHighlight = [];
                if (equipmentsByID[0] && Array.isArray(equipmentsByID[0])) { // Verificando se equipmentsByID[0] existe e é uma matriz
                    daysToHighlight = equipmentsByID[0].filter(day => day.startsWith(currentMonth)); // Filtrando apenas os dias do mês atual
                }
                resolve({ daysToHighlight });
            }, 0);

            signal.onabort = () => {
                clearTimeout(timeout);
                reject(new DOMException('aborted', 'AbortError'));
            };
        });
    }

    function DateCalendarServerRequest() {

        const [isLoading, setIsLoading] = React.useState(false);
        const [highlightedDays, setHighlightedDays] = React.useState([]);

        const fetchHighlightedDays = (date) => {
            const controller = new AbortController();
            FetchDates(date, {
                signal: controller.signal,
            })
                .then(({ daysToHighlight }) => {
                    setHighlightedDays(daysToHighlight);
                    setIsLoading(false);
                })
                .catch((error) => {
                    // ignore the error if it's caused by `controller.abort`
                    if (error.name !== 'AbortError') {
                        throw error;
                    }
                });

            requestAbortController.current = controller;
        };

        React.useEffect(() => {
            fetchHighlightedDays(initialValue);
            // abort request on unmount
            return () => requestAbortController.current?.abort();
        }, []);

        const handleMonthChange = (date) => {
            if (requestAbortController.current) {
                // make sure that you are aborting useless requests
                // because it is possible to switch between months pretty quickly
                requestAbortController.current.abort();
            }
            setIsLoading(true);
            setHighlightedDays([]);
            fetchHighlightedDays(date);
        };
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DateCalendar

                    defaultValue={initialValue}
                    loading={isLoading}
                    onMonthChange={handleMonthChange}
                    renderLoading={() => <DayCalendarSkeleton />}
                    slots={{
                        day: ServerDay,
                    }}
                    slotProps={{
                        day: {
                            highlightedDays,
                        },
                    }}
                />
            </LocalizationProvider>
        );
    }

    const renderAccordions = (status) => {
        const filteredEquipments = equipments.filter((equipment) => {
            const lastKeepalive = equipment.last_keepalive_ts_received;
            const currentDate = new Date();
            const lastKeepaliveDate = lastKeepalive ? new Date(lastKeepalive) : null;
            const differenceInDays = lastKeepaliveDate && Math.floor((currentDate - lastKeepaliveDate) / (24 * 60 * 60 * 1000));

            if (status === 'online' && differenceInDays !== null && differenceInDays <= 5) {
                return true;
            } else if (status === 'offline' && lastKeepaliveDate !== null && (differenceInDays === null || differenceInDays > 5)) {
                return true;
            } else if (status === 'unavailable' && lastKeepaliveDate === null) {
                return true;
            }

            return false;
        });

        return (
            <>
                {status === 'online' && filteredEquipments.length > 0 && (
                    <Typography>
                        <span style={{ fontSize: '1.1em', fontWeight: 'bold', color: '#4caf50' }}>Online ({filteredEquipments.length})</span> - Embarcações Ativas
                    </Typography>
                )}

                {status === 'offline' && filteredEquipments.length > 0 && (
                    <Typography>
                        <span style={{ fontSize: '1.1em', fontWeight: 'bold', color: '#f44336' }}>Offline ({filteredEquipments.length})</span> - Embarcações Inativas
                    </Typography>
                )}

                {status === 'unavailable' && filteredEquipments.length > 0 && (
                    <Typography>
                        <span style={{ fontSize: '1.1em', fontWeight: 'bold', color: '#9e9e9e' }}>Indisponível ({filteredEquipments.length})</span> - Embarcações Fora de Serviço
                    </Typography>
                )}

                <Box className="acordion" >

                    {filteredEquipments.map((equipment) => (
                        <Accordion
                            key={equipment.id}
                            expanded={expanded === `panel${equipment.id}`}
                            onChange={handleChange(`panel${equipment.id}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${equipment.id}bh-content`}
                                id={`panel${equipment.id}bh-header`}
                            >
                                {renderAvatar(equipment.last_keepalive_ts_received)}
                                <Typography sx={{ width: '33%', flexShrink: 0, ml: 2 }}>
                                    {equipment.ship_owner && equipment.display_name
                                        ? `${equipment.ship_owner} - ${equipment.display_name}`
                                        : equipment.identifier}
                                </Typography>
                            </AccordionSummary>
                            <Button data-key={equipment.id} onClick={getThisKey} sx={{ mx: 1 }}>
                                Ver Calendário
                            </Button>
                            <AccordionDetails>
                                <Typography>Serial: {equipment.serial_number}</Typography>
                                {equipment.last_keepalive_ts_received !== null && (
                                    <Typography>
                                        Última vez online: {formatDate(equipment.last_keepalive_ts_received)}
                                    </Typography>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </>
        );
    };


    const fetchDates = async (selectedKey) => {
        // Check if selectedKey is valid before making the fetch request
        if (!selectedKey) {
            console.error('Invalid button key');
            return;
        }
        const controller = new AbortController();
        try {
            setLoadingDates(true); // Set loading state to true

            const response = await fetch(`${baseUrl}/keepalive-log/by-equipment/${selectedKey}/dates`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            if (!response.ok) {
                setEquipmentsByID([]);
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setEquipmentsByID(Object.values(data));

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoadingDates(false); // Set loading state to false, whether successful or not
        }
        requestAbortController.current = controller;
    };


    const piedata = data;
    return (
        <>
            <Paper variant="outlined" sx={{ mx: 2, px: 4, py: 1, my: 1, pb: 2, }}>

                <Box sx={{ display: 'flex', mt: 2, flexDirection: 'row', flexWrap: 'wrap', gap: 2, justifyContent: 'space-around' }}>

                    <Box sx={{ minWidth: '300px', maxWidth: 1000, display: 'flex', flexDirection: 'column', flex: 1, gap: '10px' }}>
                        <Typography gutterBottom fontWeight="bold" fontSize="1.8rem" component="div">
                            Informações das embarcações
                        </Typography>

                        {renderAccordions('online')}

                        {renderAccordions('offline')}

                        {renderAccordions('unavailable')}
                    </Box>

                    <Modal
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"

                    >
                        <Box sx={style}>
                            <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                                Keep Alive
                            </Typography>
                            <Typography sx={{ p: 1, m: 1, }}>
                                Verifique as datas em que a embarcação esteve online 🟢
                            </Typography>

                            <DateCalendarServerRequest />
                        </Box>
                    </Modal>

                    <Box sx={{ maxWidth: 500, flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <PieChart
                            series={[
                                {
                                    data: piedata,
                                },
                            ]}
                            height={500}
                            padding={10}
                            margin={{ right: 150 }}
                        />

                    </Box>
                </Box>
            </Paper>
        </>
    );
};
export default StatusPage;
