// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleContainer {
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}


.btn-primary {
    background-color: #21838f;
    border-color: #21838f;
    display: block;
    width: 100%;
}


.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
    background-color: #6f828f !important;
    border-color: #6f828f !important;
}


input[type="submit"] {
    border: none;
    background: #21838f;
    color: white;
    padding: 12px 24px;
    font-size: 18px;
    border-radius: 8px;
    cursor: pointer;
}
/* 
.caixa {
   
    margin-left: 50%;
    margin-top: 40%;
    transform: translate(-50%, -50%);
    padding: 30px;
    background-color: #c7c7c711;
    border-radius: 10px;
    color: #6e6e6e;
    text-align: center;
    box-shadow: rgba(14, 30, 37, 0.02) 0px 2px 4px 0px, rgba(14, 30, 37, 0.12) 0px 2px 16px 0px;
} */`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;;;AAGA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,cAAc;IACd,WAAW;AACf;;;AAGA;;;IAGI,oCAAoC;IACpC,gCAAgC;AACpC;;;AAGA;IACI,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,eAAe;AACnB;AACA;;;;;;;;;;;;GAYG","sourcesContent":[".titleContainer {\n    display: flex;\n    flex-direction: column;\n    font-size: 2rem;\n    align-items: center;\n    justify-content: center;\n    font-weight: 600;\n}\n\n\n.btn-primary {\n    background-color: #21838f;\n    border-color: #21838f;\n    display: block;\n    width: 100%;\n}\n\n\n.btn-primary:hover,\n.btn-primary:active,\n.btn-primary:focus {\n    background-color: #6f828f !important;\n    border-color: #6f828f !important;\n}\n\n\ninput[type=\"submit\"] {\n    border: none;\n    background: #21838f;\n    color: white;\n    padding: 12px 24px;\n    font-size: 18px;\n    border-radius: 8px;\n    cursor: pointer;\n}\n/* \n.caixa {\n   \n    margin-left: 50%;\n    margin-top: 40%;\n    transform: translate(-50%, -50%);\n    padding: 30px;\n    background-color: #c7c7c711;\n    border-radius: 10px;\n    color: #6e6e6e;\n    text-align: center;\n    box-shadow: rgba(14, 30, 37, 0.02) 0px 2px 4px 0px, rgba(14, 30, 37, 0.12) 0px 2px 16px 0px;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
