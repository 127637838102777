import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom"
import MaterialUISwitch from '../themes/DarkModeToggleSwitch'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


function ResponsiveAppBar() {

    let value = 0
    function setvalue() {
        let currentUrl = window.location.href;
        if (currentUrl.includes('/home')) {
            value = 0
        }
        else if (currentUrl.includes('/events')) {
            value = 1
        }
        else if (currentUrl.includes('/status')) {
            value = 2
        }
        else if (currentUrl.includes('/vessels')) {
            value = 3
        }
    };
    setvalue();


    const user = JSON.parse(localStorage.getItem("user"))

    const navigate = useNavigate();

    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleNavigete = (path) => {
        navigate(path, { replace: true })
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const logout = () => {
        localStorage.removeItem("user");
        navigate("/login", { replace: true });

    }



    return (
        <AppBar position="static">
            <Container sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '100%', boxSizing: 'border-box', gap: '10px' }}>
                <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-Between', minWidth: '80%', boxSizing: 'border-box' }}>

                    <Tabs
                        value={value}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                    >
                        <Tab
                            key={'home'}
                            value={0}
                            label={'Dashboard'}
                            onClick={(e) => { handleNavigete('/home'); }}
                            sx={{ fontWeight: value === 0 ? 'bold' : 'normal' }} // Make the selected item bold
                        />
                        <Tab
                            key={'events'}
                            value={1}
                            label={'Eventos'}
                            onClick={(e) => { handleNavigete('/events'); }}
                            sx={{ fontWeight: value === 1 ? 'bold' : 'normal' }} // Make the selected item bold
                        />
                        <Tab
                            key={'status'}
                            value={2}
                            label={'Status'}
                            onClick={(e) => { handleNavigete('/status'); }}
                            sx={{ fontWeight: value === 2 ? 'bold' : 'normal' }} // Make the selected item bold
                        />

                    </Tabs>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar sx={{ bgcolor: '#cccccc' }} alt={user.name.toUpperCase()} src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem key={1} onClick={logout}>
                                <Typography textAlign="center">Logout</Typography>
                            </MenuItem>

                        </Menu>
                    </Box>

                </Toolbar>
                <MaterialUISwitch flow={'column'} />
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;