import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper'; // Import Paper
import { useNavigate, useLocation } from "react-router-dom";
import MaterialUISwitch from '../themes/DarkModeToggleSwitch';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import WarningIcon from '@mui/icons-material/Warning'; // Import Warning Icon
import Button from '@mui/material/Button';

function ResponsiveAppBar() {
    const [offlineCount, setOfflineCount] = React.useState(0);
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [anchorElPopover, setAnchorElPopover] = React.useState(null);

    const user = JSON.parse(localStorage.getItem("user"));
    const baseUrl = process.env.REACT_APP_LISTEN_ADDRESS;

    // Fetch offline equipment count
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseUrl}/equipment`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                const currentDate = new Date();
        
                const countOldEquipments = data.filter(equipment => {
                    const lastKeepaliveDate = equipment.last_keepalive_ts_received ? new Date(equipment.last_keepalive_ts_received) : null;
                    const differenceInDays = lastKeepaliveDate ? Math.floor((currentDate - lastKeepaliveDate) / (24 * 60 * 60 * 1000)) : null;
        
                    return differenceInDays !== null && differenceInDays > 7;
                }).length;

                setOfflineCount(countOldEquipments);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (user.token) {
            fetchData();
        }
    }, [user.token, baseUrl]);

    // Update the tab value based on the current URL
    React.useEffect(() => {
        const path = location.pathname;
        if (path.includes('/home')) {
            setValue(0);
        } else if (path.includes('/events')) {
            setValue(1);
        } else if (path.includes('/status')) {
            setValue(2);
        } else if (path.includes('/vessels')) {
            setValue(3);
        }
    }, [location.pathname]);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleNavigate = (path) => {
        navigate(path, { replace: true });
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const logout = () => {
        localStorage.removeItem("user");
        navigate("/login", { replace: true });
    };

    const handlePopoverOpen = (event) => {
        setAnchorElPopover(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorElPopover(null);
    };

    const openPopover = Boolean(anchorElPopover);
    const idPopover = openPopover ? 'simple-popover' : undefined;

    return (
        <AppBar position="static">
            <Container sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '100%', boxSizing: 'border-box', gap: '10px' }}>
                <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', minWidth: '100%', boxSizing: 'border-box' }}>
                    <Tabs
                        value={value}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                    >
                        <Tab
                            key={'home'}
                            value={0}
                            label={'Dashboard'}
                            onClick={() => handleNavigate('/home')}
                            sx={{ fontWeight: value === 0 ? 'bold' : 'normal' }}
                        />
                        <Tab
                            key={'events'}
                            value={1}
                            label={'Eventos'}
                            onClick={() => handleNavigate('/events')}
                            sx={{ fontWeight: value === 1 ? 'bold' : 'normal' }}
                        />
                        <Tab
                            key={'status'}
                            value={2}
                            label={'Status'}
                            onClick={() => handleNavigate('/status')}
                            sx={{ fontWeight: value === 2 ? 'bold' : 'normal' }}
                        />
                    </Tabs>

                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Box sx={{ px: 3 }}>
                            <IconButton size="large" color="inherit" onClick={handlePopoverOpen}>
                                <Badge badgeContent={offlineCount} color="error">
                                    <NotificationsActiveIcon color="action" />
                                </Badge>
                            </IconButton>
                            <Popover
                                id={idPopover}
                                open={openPopover}
                                anchorEl={anchorElPopover}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                               <Paper
                                    elevation={3}
                                    sx={{
                                        padding: 2,
                                        maxWidth: '350px',
                                        display: 'flex',
                                        flexDirection: 'column', // Organiza em coluna
                                        justifyContent: 'space-between', // Espaço entre os itens
                                        alignItems: 'flex-start', // Alinha itens à esquerda
                                        backgroundColor: '#f8d7da', // Cor de fundo para erro
                                        borderColor: '#f5c6cb', // Cor da borda
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                    }}
                                    >
                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <WarningIcon sx={{ marginRight: 1, color: '#721c24', fontSize: '32px' }} />
                                        <Box>
                                        <Typography sx={{ fontWeight: 'bold', color: '#721c24' }}>
                                            Atenção!
                                        </Typography>
                                        <Typography sx={{ color: '#721c24' }}>
                                            {offlineCount} embarcações offline há mais de 7 dias!
                                        </Typography>
                                        </Box>
                                    </Box>
                                    <Button onClick={() => handleNavigate('/status')} size="small" sx={{ alignSelf: 'flex-end', marginTop: 0 }}><strong>Detalhes</strong></Button> {/* Alinha o botão à esquerda */}
                                </Paper>
                            </Popover>
                        </Box>

                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar sx={{ bgcolor: '#cccccc' }} alt={user.name.toUpperCase()} src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem key={1} onClick={logout}>
                                <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
                <MaterialUISwitch flow={'column'} />
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;
