// OnlineCircle.js
import { styled } from '@mui/system';
import { green } from '@mui/material/colors';

const OnlineCircle = styled('div')(({ theme }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: '50%', // To create a circle
    boxShadow: '0 2px 5px rgba(255, 255, 255, 0.2)', // Lighter shadow on a black background
    backgroundColor: green[500], // More vivid green
    transition: 'background-color 0.3s, transform 0.3s, box-shadow 0.3s',
    '&:hover': {
        backgroundColor: green[500],
        transform: 'scale(0.9)', // Slight scale down on hover
        boxShadow: '0 4px 8px rgba(255, 255, 255, 0.3)', // Adjusted lighter shadow on hover
    },
}));

export default OnlineCircle;
